import Api from 'Api/Api';
import { APP_NAMES, IDENTITY_SERVICE_BASE_URL } from 'config/constants';

export async function validateEmail(value: string): Promise<any> {
  const abortControllers: AbortController[] = [];
  const promises = APP_NAMES.map((app: string) => {
    const controller = new AbortController();
    abortControllers.push(controller);

    return Api.get({
      url: `${IDENTITY_SERVICE_BASE_URL}/user`,
      config: {
        signal: controller.signal,
        headers: {
          country: 'BR'
        },
        params: { value, appName: app, ignoreCountry: true }
      }
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          abortControllers.forEach((ctrl) => ctrl.abort());

          return response.data[0];
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  });
  const results = await Promise.all(promises);
  const validResult = results.find((result) => !!result);
  return validResult;
}

export async function getUserInfoByList(ids: string): Promise<any> {
  const users = [];
  const idsLength = ids.split(',').length;
  const abortControllers: AbortController[] = [];
  const promises = APP_NAMES.map((app: string) => {
    const controller = new AbortController();
    abortControllers.push(controller);

    return Api.get({
      url: `${IDENTITY_SERVICE_BASE_URL}/user/list`,
      config: {
        signal: controller.signal,
        headers: {
          country: 'BR'
        },
        params: { ids, appName: app, ignoreCountry: true }
      }
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          users.push(...response.data);
          if (users.length === idsLength) {
            abortControllers.forEach((ctrl) => ctrl.abort());
          }
          return users;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  });
  const results = await Promise.all(promises);
  const validResult = results.find((result) => !!result);
  return validResult;
}

export default { validateEmail, getUserInfoByList };
